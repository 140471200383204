<template>
	<div class="shippingTemplates">
		<div class="filter-container">
			<!-- 搜索 -->
			<div class="filter-item">
				<label class="label">模版名称:</label>
				<el-input v-model="name" style="width: 200px;" clearable placeholder="请输入模版名称"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin: 0 20px;" @click="handleFilter">查询</el-button>
				<buttonPermissions :datas="'addPostage'">
					<el-button type="primary" @click="addTemptet({})">添加</el-button>
				</buttonPermissions>
			</div>
		</div>

		<!-- 运费模板 -->
		<el-table :data="allData" border style="width: 100%">
			<el-table-column prop="templateName" label="模版名称"></el-table-column>
			<el-table-column prop="modifyTime" label="修改时间"></el-table-column>
			<el-table-column label="操作">
				<template slot-scope="scop">
					<div style="display:flex;">
						<buttonPermissions :datas="'editPostage'">
							<span style="margin:0 5px;cursor: pointer;color:#0000FF" @click="copyTemptet(scop.row)">复制</span>
						</buttonPermissions>
						<buttonPermissions :datas="'editPostage'">
							<span style="margin:0 5px;cursor: pointer;color:#0000FF" @click="addTemptet(scop.row)">修改</span>
						</buttonPermissions>
						<buttonPermissions :datas="'deletePostage'">
							<span
								style="color:red;cursor: pointer;"
								@click="delecTemplate(scop.row)"
								v-if="!scop.row.IsUsing"
							>删除</span>
						</buttonPermissions>
					</div>
				</template>
			</el-table-column>
		</el-table>
        <div>
            <el-pagination
                v-if="total"
                style="margin:20px 0;float:right;"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNo"
                :page-sizes="[20, 40, 60, 80, 100]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
	</div>
</template>

<script>
import {
	freightTemplateList,
	freightTemplatedeletet
} from '@/api/goods'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions
	},
	data () {
		return {
			name: '',   //运费模版查询-名称
			total: 0,   
			pageNo: 1,
			pageSize: 20,
			allData: [],
			loading: false,
		}
	},
	created () {
		this.getList();

	},
	methods: {
		handleFilter () {
			this.pageNo = 1;
			this.getList();
		},

		// 获取模板数据
		async getList () {
			this.loading = true;
			try {
				//处理地址信息
                let params = {
                    name: this.name,
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                }
				let result = await freightTemplateList(params);
                this.allData = result.data.records;
                this.total = result.data.total;
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},

		// 添加运费模板
		async addTemptet (record) {
			this.$router.push({
				path: '/setting/addTemplate',
				query: {
					templateId: record.id ? record.id : 0
				}
			});
		},
		// 复制运费模板
		async copyTemptet (record) {
			this.$router.push({
				path: '/setting/addTemplate',
				query: {
					templateId: record.id ? record.id : 0,
					type: 'copy'
				}
			});
		},

		// 删除运费模板
		delecTemplate (record) {
			this.$confirm('删除后不可恢复，是否确认确认继续删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {

				this.loading = true;

				try {
					let result = await freightTemplatedeletet({
						id: record.id
					});

					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '删除成功'
						});
					}
				} catch (e) {
					console.log(e)
				} finally {
					setTimeout(() => {
						this.loading = false;
						this.getList();
					}, 100)
				}


			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消删除'
				});
			})
		},

		// 切换显示条数
		handleSizeChange (val) {
			this.pageSize = val;
			this.getList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.pageNo = val;
			this.getList();
		},



	}
}
</script>

<style lang="less">
.shippingTemplates {
	width: 100%;
	background: #fff;
    padding: 30px;
    padding-bottom: 80px;

	.text {
		font-size: 14px;
	}

	.item {
		margin-bottom: 18px;
	}

	.clearfix:before,
	.clearfix:after {
		display: table;
		content: "";
	}

	.clearfix:after {
		clear: both;
	}

	.box-card {
		width: 1100px;
		margin-top: 30px;
	}
}
</style>
